// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { DashboardOutlined, BarChartOutlined, DotChartOutlined, DeploymentUnitOutlined, RadarChartOutlined } from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  DashboardOutlined,
  BarChartOutlined,
  DotChartOutlined,
  DeploymentUnitOutlined,
  RadarChartOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const global: NavItemType = {
  id: 'global',
  title: <FormattedMessage id="Analytics" />,
  icon: icons.DashboardOutlined,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="Dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: icons.DashboardOutlined
    },
    {
      id: 'reports',
      title: <FormattedMessage id="Custom Report" />,
      type: 'item',
      url: '/custom-report',
      icon: icons.BarChartOutlined
    },
    {
      id: 'savedReports',
      title: <FormattedMessage id="Saved Reports" />,
      type: 'item',
      url: '/saved-reports',
      icon: icons.RadarChartOutlined
    }
  ]
};

export const globalPA: NavItemType = {
  id: 'global',
  title: <FormattedMessage id="Analytics" />,
  icon: icons.DashboardOutlined,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="Dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: icons.DashboardOutlined
    },
    {
      id: 'reports',
      title: <FormattedMessage id="Custom Report" />,
      type: 'item',
      url: '/custom-report',
      icon: icons.BarChartOutlined
    },
    {
      id: 'savedReports',
      title: <FormattedMessage id="Saved Reports" />,
      type: 'item',
      url: '/saved-reports',
      icon: icons.RadarChartOutlined
    }
  ]
};

export default global;
