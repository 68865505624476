// third-party
import { FormattedMessage } from 'react-intl';

// assets
import VpnLockIcon from '@mui/icons-material/VpnLock';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  VpnLockIcon,
  PeopleAltIcon
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const demand: NavItemType = {
  id: 'other',
  title: <FormattedMessage id="Global" />,
  type: 'group',
  children: [
    {
      id: 'Targeting',
      title: <FormattedMessage id="Targeting" />,
      type: 'item',
      url: '/targeting',
      icon: icons.VpnLockIcon
    },
    {
      id: 'Users',
      title: <FormattedMessage id="Users" />,
      type: 'item',
      url: '/users',
      icon: icons.PeopleAltIcon
    }
  ]
};

export default demand;
