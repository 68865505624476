import { lazy } from "react";

// project import
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
// pages routing
const Dashboard = Loadable(lazy(() => import("pages/dashboard/default")));
const Tags = Loadable(lazy(() => import("pages/tags/Tags")));
const Advertisers = Loadable(
  lazy(() => import("pages/advertisers/Advertisers"))
);
const Publishers = Loadable(lazy(() => import("pages/publishers/Publishers")));
const Waterfalls = Loadable(lazy(() => import("pages/waterfall/Waterfall")));
const CustomReports = Loadable(
  lazy(() => import("pages/reports/CustomReport"))
);
const Targeting = Loadable(
  lazy(() => import("pages/predefinedTargeting/PredefinedTargeting"))
);
const Bidders = Loadable(lazy(() => import("pages/bidders/Bidders")));
const ReportsTable = Loadable(lazy(() => import("pages/reports/ReportsTable")));
const DemandSystems = Loadable(
  lazy(() => import("pages/demandSystem/DemandSystem"))
);
const JSONParamsTable = Loadable(
  lazy(() => import("pages/jsonParams/JSONParams"))
);
const Users = Loadable(lazy(() => import("pages/users/Users")));
const ExtendedAnalytics = Loadable(
  lazy(() => import("../components/third-party/GiladCheckComponent"))
);
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "tags",
          element: <Tags />,
        },
        {
          path: "advertisers",
          element: <Advertisers />,
        },
        {
          path: "publishers",
          element: <Publishers />,
        },
        {
          path: "custom-report/:id",
          element: <CustomReports />,
        },
        {
          path: "custom-report",
          element: <CustomReports />,
        },
        {
          path: "demand-systems",
          element: <DemandSystems />,
        },
        {
          path: "json-params",
          element: <JSONParamsTable />,
        },
        {
          path: "extended-analytics",
          element: <ExtendedAnalytics />,
        },
        {
          path: "saved-reports",
          element: <ReportsTable />,
        },
        {
          path: "placements",
          element: <Waterfalls />,
        },
        {
          path: "targeting",
          element: <Targeting />,
        },
        {
          path: "bidders",
          element: <Bidders />,
        },
        {
          path: "users",
          element: <Users />,
        },
      ],
    },
  ],
};

export default MainRoutes;
