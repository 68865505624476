// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
import { ConfigProvider, theme } from 'antd';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';

// auth-provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import useConfig from 'hooks/useConfig';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const { mode } = useConfig();

  const getTheme = () => {
    if (mode === 'dark') {
      return theme.darkAlgorithm;
    } else {
      return theme.defaultAlgorithm;
    }
  };

  return (
    <ThemeCustomization>
      <ConfigProvider
        theme={{
          algorithm: getTheme()
        }}
      >
      <Locales>
        <ScrollTop>
          <AuthProvider>
            <>
              <Notistack>
                <Routes />
                <Snackbar />
              </Notistack>
            </>
          </AuthProvider>
        </ScrollTop>
      </Locales>
      </ConfigProvider>
    </ThemeCustomization>
  )
};

export default App;
