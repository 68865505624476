import { Account } from './Account';
import { Model } from './Model';

export interface UserProfile extends Model {
  email?: string;
  password?: string;
  role?: number;
  tier?: string;
  ownedAccounts?: Account[];
  subAccountID?: number;
}

export enum UserRole {
  root = 1,
  admin = 2,
  publisher = 3,
  advertiser = 4,
  sales = 5
}
