// project import
import other from './other';

// types
import { NavItemType } from 'types/menu';
import demand from './demand';
import global, { globalPA } from './global';
import supply, { salesDemand } from './supply';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [global, demand, supply, other]
};

export const menuItemsPA: { items: NavItemType[] } = {
  items: [globalPA]
};

export const menuItemsSales: { items: NavItemType[] } = {
  items: [globalPA, salesDemand]
};

export default menuItems;
