// material-ui
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Stack, Tab, Tabs } from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import { useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import useAuth from 'hooks/useAuth';
import { EditAccountSection } from './EditAccountSection';
import axiosServices from 'utils/axios';
import { Account } from 'types/entities/Account';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export interface EditAccountProps {
  open: boolean;
  onClose: () => void;
}

export const EditAccount: React.FC<EditAccountProps> = (props: EditAccountProps) => {
  let selectedTab = 0;
  const [value, setValue] = useState(selectedTab);
  const { open, onClose } = props;
  const { account, setAccount } = useAuth();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const UserSchema = Yup.object().shape({
    name: Yup.string().max(255).required('Name is required')
  });

  const formik = useFormik({
    initialValues: account!,
    validationSchema: UserSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      try {
        account!.name = values.name;
        axiosServices.put<Account>(`/api/account/${account!.ID}/`, values).then((result) => {
          setAccount(result.data);
        });
        setSubmitting(false);
        onClose();
      } catch (error) {
        console.error(error);
      }
    }
  });

  const { handleSubmit } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" id="myForm" onSubmit={handleSubmit}>
        <Dialog
          maxWidth="lg"
          fullWidth
          open={open}
          // onClose={onClose}
          PaperProps={{
            sx: {
              minHeight: '80%',
              maxHeight: '80%'
            }
          }}
          sx={{ '& .MuiDialog-paper': { p: 0 } }}
        >
          <DialogTitle>Edit Account</DialogTitle>
          <DialogContent dividers={false} sx={{ padding: 0 }}>
            <MainCard border={false} sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="account profile tab">
                  <Tab label="Settings" icon={<SettingsIcon />} iconPosition="start" />
                </Tabs>
              </Box>
              <Box>
                <TabPanel value={value} index={0}>
                  <EditAccountSection />
                </TabPanel>
              </Box>
            </MainCard>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ p: 2.5 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item></Grid>
              <Grid item>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button color="error" onClick={() => onClose()}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" form="myForm">
                    Save
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Form>
    </FormikProvider>
  );
};
