import { Grid, InputLabel, Stack, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import useAuth from 'hooks/useAuth';
import { Account } from 'types/entities/Account';

export const EditAccountSection: React.FC = () => {
  const { user } = useAuth();
  const { touched, errors, getFieldProps } = useFormikContext<Account>();

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Typography variant="h3" sx={{ mb: '16px' }}>
          Info
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack spacing={1.25}>
              <InputLabel htmlFor="name">Name</InputLabel>
              <TextField
                fullWidth
                id="name"
                disabled={user!.role !== 1}
                placeholder="Exchange Name"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1.25}>
              <InputLabel htmlFor="domain">Domain</InputLabel>
              <TextField
                fullWidth
                disabled={user!.role !== 1}
                id="domain"
                placeholder="Primary Domain"
                {...getFieldProps('domain')}
                error={Boolean(touched.domain && errors.domain)}
                helperText={touched.domain && errors.domain}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1.25}>
              <InputLabel htmlFor="servingURL">Serving URL</InputLabel>
              <TextField
                fullWidth
                id="servingURL"
                disabled={user!.role !== 1}
                placeholder="Serving Domain"
                {...getFieldProps('servingURL')}
                error={Boolean(touched.servingURL && errors.servingURL)}
                helperText={touched.servingURL && errors.servingURL}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1.25}>
              <InputLabel htmlFor="name">Company Name</InputLabel>
              <TextField
                fullWidth
                id="company"
                placeholder="Company Name"
                {...getFieldProps('company')}
                error={Boolean(touched.company && errors.company)}
                helperText={touched.company && errors.company}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1.25}>
              <InputLabel htmlFor="name">Contact</InputLabel>
              <TextField
                fullWidth
                id="contactEmail"
                placeholder="Contact Email"
                {...getFieldProps('contactEmail')}
                error={Boolean(touched.contactEmail && errors.contactEmail)}
                helperText={touched.contactEmail && errors.contactEmail}
              />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item xs={12} md={4}>
        <Typography variant="h3" sx={{ mb: '16px' }}>
          Custom Params Name
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack spacing={1.25}>
              <InputLabel htmlFor="custom1">Custom 1</InputLabel>
              <TextField
                fullWidth
                id="custom1"
                placeholder="Custom 1"
                {...getFieldProps('custom1')}
                error={Boolean(touched.custom1 && errors.custom1)}
                helperText={touched.custom1 && errors.custom1}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1.25}>
              <InputLabel htmlFor="custom1">Custom 2</InputLabel>
              <TextField
                fullWidth
                id="custom2"
                placeholder="Custom 2"
                {...getFieldProps('custom2')}
                error={Boolean(touched.custom2 && errors.custom2)}
                helperText={touched.custom2 && errors.custom2}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1.25}>
              <InputLabel htmlFor="custom3">Custom 3</InputLabel>
              <TextField
                fullWidth
                id="custom3"
                placeholder="Custom 3"
                {...getFieldProps('custom3')}
                error={Boolean(touched.custom3 && errors.custom3)}
                helperText={touched.custom3 && errors.custom3}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1.25}>
              <InputLabel htmlFor="custom1">Custom 4</InputLabel>
              <TextField
                fullWidth
                id="custom4"
                placeholder="Custom 4"
                {...getFieldProps('custom4')}
                error={Boolean(touched.custom4 && errors.custom4)}
                helperText={touched.custom4 && errors.custom4}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1.25}>
              <InputLabel htmlFor="custom1">Custom 5</InputLabel>
              <TextField
                fullWidth
                id="custom5"
                placeholder="Custom 5"
                {...getFieldProps('custom5')}
                error={Boolean(touched.custom5 && errors.custom5)}
                helperText={touched.custom5 && errors.custom5}
              />
            </Stack>
          </Grid>
        </Grid>
      </Grid> */}
    </Grid>
  );
};
