// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  OrderedListOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  CameraOutlined,
  SwapOutlined,
  BlockOutlined,
  SettingOutlined,
  FileTextOutlined,
  PlaySquareOutlined,
} from "@ant-design/icons";

// type
import { NavItemType } from "types/menu";

// icons
const icons = {
  SettingOutlined,
  OrderedListOutlined,
  BlockOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  CameraOutlined,
  SwapOutlined,
  FileTextOutlined,
  PlaySquareOutlined,
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const demand: NavItemType = {
  id: "supply",
  title: <FormattedMessage id="Supply" />,
  icon: icons.OrderedListOutlined,
  type: "group",
  children: [
    {
      id: "Publishers",
      title: <FormattedMessage id="Publishers" />,
      type: "item",
      url: "/publishers",
      icon: icons.CameraOutlined,
    },
    {
      id: "placement",
      title: <FormattedMessage id="Placements" />,
      type: "item",
      url: "/placements",
      icon: icons.BlockOutlined,
    },
    {
      id: "jsonParams",
      title: <FormattedMessage id="JSON Params" />,
      type: "item",
      url: "/json-params",
      icon: icons.FileTextOutlined,
    },
    {
      id: "extended-analytics",
      title: <FormattedMessage id="Extended Analytics" />,
      type: "item",
      url: "/extended-analytics",
      icon: icons.DeploymentUnitOutlined,
    },
  ],
};

export const salesDemand: NavItemType = {
  id: "supply",
  title: <FormattedMessage id="Supply" />,
  icon: icons.OrderedListOutlined,
  type: "group",
  children: [
    {
      id: "Publishers",
      title: <FormattedMessage id="Publishers" />,
      type: "item",
      url: "/publishers",
      icon: icons.CameraOutlined,
    },
    {
      id: "placement",
      title: <FormattedMessage id="Placements" />,
      type: "item",
      url: "/placements",
      icon: icons.BlockOutlined,
    },
  ],
};

export default demand;
