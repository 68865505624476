import { useRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Tooltip } from '@mui/material';

// project import
import IconButton from 'components/@extended/IconButton';
import useConfig from 'hooks/useConfig';
import { RetweetOutlined } from '@ant-design/icons';

// assets

// ==============================|| HEADER CONTENT - Theme Changer ||============================== //

const OrientationChange = () => {
  const theme = useTheme();
  const { menuOrientation, onChangeMenuOrientation } = useConfig();

  const anchorRef = useRef<any>(null);

  const handleToggle = () => {
    if (menuOrientation === 'vertical') {
      onChangeMenuOrientation('horizontal');
    } else {
      onChangeMenuOrientation('vertical');
    }
  };

  // const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Tooltip title="Menu Orientation">
        <IconButton
          color="secondary"
          variant="light"
          sx={{ color: 'text.primary', bgcolor: iconBackColor }}
          aria-label="open profile"
          ref={anchorRef}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <RetweetOutlined />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default OrientationChange;
