// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { TagsOutlined, TagOutlined, QuestionOutlined, DeploymentUnitOutlined, CameraOutlined, SwapOutlined } from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  TagsOutlined,
  TagOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  CameraOutlined,
  SwapOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const demand: NavItemType = {
  id: 'demand',
  title: <FormattedMessage id="Demand" />,
  icon: icons.TagsOutlined,
  type: 'group',
  children: [
    {
      id: 'advertisers',
      title: <FormattedMessage id="Advertisers" />,
      type: 'item',
      url: '/advertisers',
      icon: icons.CameraOutlined
    },
    {
      id: 'demandSystem',
      title: <FormattedMessage id="Demand System" />,
      type: 'item',
      url: '/demand-systems',
      icon: icons.TagsOutlined
    },
    {
      id: 'tags',
      title: <FormattedMessage id="Tags" />,
      type: 'item',
      url: '/tags',
      icon: icons.TagOutlined
    },
    {
      id: 'bidAdapters',
      title: <FormattedMessage id="Bid Adapters" />,
      type: 'item',
      url: '/bidders',
      icon: icons.SwapOutlined
    }
  ]
};

export default demand;
